import React from "react"
import { graphql } from "gatsby"
import Post from "../components/template-parts/faq-part"

const Faq = ({ location, data }) => {

return (
<Post data={data} site={data.site} location={location}/>
)}

export default Faq

export const query = graphql`
  query faq($id: String!) {
    page: wpFaq(id: { eq: $id }) {
      title
      content
    }
    site {
      siteMetadata {
        author
        description
        title
      }
    }
  }
`
