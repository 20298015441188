import React from "react"
import { Link } from "gatsby"
import parse from "html-react-parser"

import Layout from "../layout"
import Seo from "../seo"
import Share from "../share"

function FaqPart({ data, site, location }) {

  const { title, content } = data.page

  return (
    <Layout>
    <Seo
        title={site.siteMetadata.title}
        description={site.siteMetadata.description}
      />
      <div className="static">

      <section id="static-no-hero">&nbsp;</section>
        <section id="static-page" className="page-pad">
          <div className="page">
            <div className="column-single">
              <h2>{title}</h2>



              <span>{parse(content)}</span>
              <div className="go-to-faqs">          <Link to="/frequently-asked-questions">
            <span >View more of our frequently asked questions.</span>
          </Link></div>
            </div>
            
          </div>
        </section>
        <Share location={location} title={title} site={site} />
      </div>
    </Layout>
  )
}

export default FaqPart
